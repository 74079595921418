<template>
  <div class="edit-integration-web-api">
    <div class="property-container">
      <div class="label-container">Web API URL:</div>
      <div class="value-container">
        <BaseUrlInput v-model="integrationData.data.url" />
      </div>
    </div>
    <div class="property-container">
      <div class="label-container">Token Header:</div>
      <div class="value-container">
        <BaseTextInput v-model="integrationData.data.token_header" />
      </div>
    </div>
    <div class="property-container">
      <div class="label-container">Token:</div>
      <div class="value-container">
        <BaseTextInput v-model="integrationData.data.token" />
      </div>
    </div>
    <div class="property-container">
      <div class="label-container">Payload:</div>
      <div class="value-container">
        <JsonInput v-model:value="integrationData.data.payload" />
      </div>
    </div>
  </div>
</template>

<script>
import JsonInput from './JsonInput.vue';
import BaseTextInput from '../../components/base/BaseTextInput.vue';
import BaseUrlInput from '../../components/base/BaseUrlInput.vue';
import { validateUrl } from '../../utils/ValidationUtils';
import { toRaw } from 'vue';

export default {
  name: 'EditIntegrationWebApi',
  components: {
    BaseTextInput,
    JsonInput,
    BaseUrlInput
  },
  props: {
    integration: {
      type: Object,
      required: true
    }
  },
  emits: ['update'],
  data() {
    return {
      integrationData: {}
    };
  },
  computed: {
    isSaveEnabled() {
      let isPayloadValid = true;
      try {
        if (this.integrationData.data.payload) {
          JSON.parse(this.integrationData.data.payload);
        }
      } catch (error) {
        isPayloadValid = false;
      }
      return validateUrl(this.integrationData.data.url) && isPayloadValid;
    }
  },
  created() {
    this.integrationData = structuredClone(toRaw(this.integration));
    if (!this.integrationData.data.url) {
      this.integrationData.data.url = '';
    }
    if (!this.integrationData.data.token_header) {
      this.integrationData.data.token_header = '';
    }
    if (!this.integrationData.data.token) {
      this.integrationData.data.token = '';
    }
    if (!this.integrationData.data.payload) {
      this.integrationData.data.payload = '{}';
    } else {
      this.integrationData.data.payload = JSON.stringify(this.integrationData.data.payload, null, 4);
    }
  },
  mounted() {
    this.$emit('update');
  },
  updated() {
    this.$emit('update');
  },
  methods: {
    getDiffAttributes() {
      const origin = this.integration.data;
      const diff = {};
      Object.keys(this.integrationData.data).forEach(key => {
        if (JSON.stringify(this.integrationData.data[key]) !== JSON.stringify(origin[key])) {
          diff[key] = this.integrationData.data[key];
        }
      });

      if ('payload' in diff) {
        diff.payload = JSON.parse(diff.payload);
      }
      return diff;
    }
  }
};
</script>

<style scoped lang="scss">
.edit-integration-web-api {
  .property-container {
    margin-top: 20px;
    .label-container {
      color: var(--secondaryTextColor);
      margin-bottom: 5px;
    }
    .value-container {
      &.text-value {
        color: var(--secondaryTextColor);
      }
    }
  }
}
</style>
