<template>
  <div class="url-input">
    <span class="url-hint" @click="focusOnInput">https://</span>
    <input
      ref="textInput"
      type="text"
      class="input-text"
      :value="modelValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :maxlength="maxlength"
      @input="$emit('update:modelValue', $event.target.value)"
      @blur="$emit('blur', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseUrlInput',
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxlength: {
      default: null,
      type: [null, Number]
    }
  },
  emits: ['update:modelValue', 'blur'],
  methods: {
    focusOnInput() {
      this.$refs?.textInput?.focus?.();
    }
  }
};
</script>

<style lang="scss" scoped>
.url-input {
  display: flex;
  align-items: baseline;
  background-color: var(--primaryColorDarkShade1);
  border-radius: 7px;
  padding: 0.6rem 1rem;
  min-width: 15rem;

  .url-hint {
    color: var(--disabledColor);
    user-select: none;
  }

  .input-text {
    font-family: var(--font-family-secondary);
    font-size: inherit;
    padding-left: 0.3rem;
    outline: none;
    border: 0;
    width: 100%;
    box-sizing: border-box;
    color: var(--textColor);
    caret-color: var(--highlightColor);
    background: inherit;
  }

  &:disabled {
    opacity: 0.5;
    filter: grayscale(0.5);
  }

  &:focus-within {
    outline: 2px solid var(--secondaryOverlayShade2);
  }
}
</style>
